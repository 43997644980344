import React from "react";
import Linkify from "react-linkify";
import { INSTRUCTORS } from "../../Constants/EventsConstants/EventsConstants";
import gmail_icon from "../../Resources/Images/gmail-logo.png";
import linkedin_icon from "../../Resources/Images/linkedinLogo.png";
import "./styles/Instructor.css";

const Instructor = (props) => {
    const event_details = props.event_details;

    return (
        <div className="instructors-wrapper">
            <h3>Meet Your Instructor</h3>

            {event_details.speakers.map((speaker) => {
                return (
                    <div className="instructors-content-image-wrapper">
                        <div className="instructors-img-wrapper">
                            <img src={INSTRUCTORS[speaker.id].image} alt="" />
                            <div className="instructor-details">
                                <div className="instructor-name-and-links">
                                    <h4>{speaker.name}</h4>
                                    <a href={INSTRUCTORS[speaker.id].linkedIn}>
                                        <img className="instructor-ln" src={linkedin_icon} alt="" />
                                    </a>
                                    <a href={INSTRUCTORS[speaker.id].gmail}>
                                        <img className="instructor-gmail" src={gmail_icon} alt="" />
                                    </a>
                                </div>
                                <div className="instructors-job-details-wrapper">
                                    {speaker.description}
                                </div>
                            </div>
                        </div>
                        <div className="instructors-content-wrapper">
                            <Linkify>
                                <div
                                    className="instructors-job-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: speaker.about,
                                    }}
                                ></div>
                            </Linkify>
                            {/* <p className="instructors-job-desc">{speaker.about}</p> */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Instructor;
