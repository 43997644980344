const eventFaqConstants = [
    {
        id: 1,
        question: "Are all events live or recorded ?",
        answer: "All the events will be live and recordings for the same will also be provided to you, in case you miss to attend it Live.",
    },
    {
        id: 2,
        question: "Will I receive a certificate ?",
        answer: "Yes, you will receive a certificate at the end of the event.",
    },
    {
        id: 3,
        question: "Who is the speaker of this event ?",
        answer: "The speaker/teacher would be mentioned above in this page. All our speakers are from top universities like IIT, etc. and top companies like Amazon, Tower Research Capital, etc. ",
    },
    {
        id: 4,
        question: "Where will I receive the link of the session(s) ?",
        answer: "You will receive it on the Email-ID and WhatsApp phone number you register with.",
    },
    {
        id: 5,
        question: "What is the language of teaching ?",
        answer: "It will be in English. ",
    },
    {
        id: 6,
        question: "Can I enrol for more than one event ?",
        answer: "Yes, of course!",
    },
    {
        id: 7,
        question: "Is the cohort going to have any projects?",
        answer: (
            <div style={{ marginLeft: "20px" }}>
                Yes, there are going to be projects covered throughout the course.
                <br />
                <br />
                <strong>HTML & CSS</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>Swiggy Clone: Static and Responsive frontend (Guided)</p>
                    <p>Flipkart Clone: Static and Responsive frontend (Self Practice)</p>
                </div>
                <br />
                <strong>Javascript</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>Real Time Weather Data for any location world (Guided)</p>
                    <p>Realtime News Application like Google news (Self Practice)</p>
                </div>
                <br />
                <strong>NodeJs</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>
                        Tours Information Website (Server Side Rendering) managed using a local file
                        system database (Guided)
                    </p>
                    <p>
                        Products Website (Server Side Rendering) managed using a local file system
                        database (Self Practice)
                    </p>
                </div>
                <br />
                <strong>ExpressJs</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>APIs for Tours Website managed using file system (Guided)</p>
                </div>
                <br />
                <strong>MongoDB and Mongoose</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>Fully functional backend for products website like Amazon (Guided)</p>
                </div>
                <br />
                <strong>ReactJs</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>
                        Responsive frontend of E-commerce Platform like Amazon / Flipkart (Guided)
                    </p>
                </div>
                <br />
                <strong>Context API</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>
                        Hooks and Redux Fully functional backend for products website like Amazon
                        (Guided)
                    </p>
                    <p>AI-based Image Generation Application (Guided)</p>
                </div>
                <br />
                <strong>Deployment</strong>
                <br />
                <div style={{ marginLeft: "40px" }}>
                    <p>Deploy your projects on the web.</p>
                </div>
            </div>
        ),
    },
    {
        id: 8,
        question: "Is the cohort beginner friendly?",
        answer: "Yes, the course will cover everything from scratch. The student needs to give 20 Hrs per week of Self-effort to enjoy the flow and learn development in its best way possible.",
    },
    {
        id: 9,
        question: "How can we clarify our doubts?",
        answer: "There will be doubt solving sessions every week. Also, there is 24*7 doubt support available on the platform to have all your queries and doubts resolved.",
    },
    {
        id: 10,
        question: "Will we get a summer training certificate?",
        answer: "Yes, everyone who attends the course will get the certificate given that they satisfy the minimum requirements.",
    },
];

const summerFaqConstants = [
    {
        id: 1,
        question: "Are all Summer Courses live or recorded ?",
        answer: "All these Summer Courses will have recorded lectures along with access to our dashboard, where you will have a full fledged learning experience.",
    },
    {
        id: 2,
        question: "Will I receive a certificate ?",
        answer: "Yes, you will receive a certificate at the end of the event.",
    },
    {
        id: 3,
        question: "Who is the speaker of this event ?",
        answer: "The speaker/teacher would be mentioned above in this page. All our speakers are from top universities like IIT, etc. and top companies like Amazon, Tower Research Capital, etc. ",
    },
    {
        id: 4,
        question: "Where will I receive the link of the session(s) ?",
        answer: "You will receive it on the Email-ID and WhatsApp phone number you register with.",
    },
    {
        id: 5,
        question: "What is the language of teaching ?",
        answer: "It will be in English. ",
    },
    {
        id: 6,
        question: "Can I enrol for more than one event ?",
        answer: "Yes, of course!",
    },
];

export { eventFaqConstants, summerFaqConstants };
