import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/TestimonialsAndCertificate.css";
import {
    textTestimonialCardData,
    videoTestimonialCardData,
} from "./TestimonialsAndCertificateConstants";
import Rating from "@mui/material/Rating";
import YouTube from "react-youtube";
import quoteIcon from "../../Resources/Images/quoteIcon.svg";
import linkedinIcon from "../../Resources/Images/linkedinLogo.png";
import arrowPrev from "../../Resources/Images/arrowPrev.png";
import arrowNext from "../../Resources/Images/arrowNext.png";
function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const Testimonials = () => {
    const [value, setValue] = React.useState(5);

    const youtubePlayer = (videoId) => {
        const opts = {
            height: window.innerWidth < 600 ? "170" : "300",
            width: "100%",
        };

        return (
            <YouTube
                style={{
                    margin: "1vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                videoId={videoId}
                opts={opts}
            />
        );
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    const getTestimonials = () => {
        return (
            <div className="testimonial-wrapper">
                <h3 className="testimonials-heading">Experiences</h3>
                <div className="testimonials-card-wrapper text-testimonial-card-wrapper">
                    <Slider {...sliderSettings}>
                        {textTestimonialCardData &&
                            textTestimonialCardData.map((success_story, index) => (
                                <div className="success-stories-card">
                                    <div className="success-stories-content">
                                        <img
                                            className="success-stories-card-quotes-img"
                                            src={quoteIcon}
                                            alt=""
                                        />
                                        <div className="success-stories-card-txt">
                                            <p>{success_story.text}</p>
                                        </div>
                                        <img
                                            className="success-stories-card-img"
                                            src={success_story.img}
                                            alt=""
                                        />
                                        <div className="success-stories-name-wrapper">
                                            <p className="success-stories-name">
                                                {success_story.name}
                                            </p>
                                            <a href={success_story.link}>
                                                <img src={linkedinIcon} alt="" />
                                            </a>
                                        </div>
                                        <div className="success-stories-company-logo-wrapper">
                                            <p className="sde-text">
                                                {success_story.job_description}
                                            </p>
                                            <div className="logo-wrapper">
                                                <img src={success_story.company_logo} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Slider>
                </div>
                {/* <div className="testimonials-card-wrapper">
                    {videoTestimonialCardData &&
                        videoTestimonialCardData.map((data, index) => (
                            <div className="testimonials-card" key={index}>
                                    {youtubePlayer(data.videoId)}
                                <div className="testimonial-contents-wrapper">
                                    <h4>{`${data.name}'s Story`}</h4>
                                    <Rating name="read-only" value={value} readOnly />
                                </div>
                            </div>
                        ))}
                </div> */}
                <div
                    className="success-stories-btn-wrapper"
                    onClick={() =>
                        window.open(
                            "https://renaissance.programmingpathshala.com/crack-coding-interviews/stories",
                            "_blank"
                        )
                    }
                >
                    <button>Read 100+ Success Stories</button>
                </div>
            </div>
        );
    };

    return <div className="testimonials-wrapper">{getTestimonials()}</div>;
};

export default Testimonials;
