import React, { useEffect, useState } from "react";
import { EVENT_TYPES } from "../../Constants/EventsConstants/EventsConstants";
import CustomMadeAccordion from "../CustomMadeAccordion/CustomMadeAccordion";
import { eventFaqConstants } from "./EventFaqConstants";
import "./styles/EventFaq.css";

const EventFaq = (props) => {
    const event_details = props.event_details;
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        // TODO : Remove this, it is a hotfix
        if (event_details.event_id == 99) {
            setFaqData(eventFaqConstants);
            return;
        }

        if (event_details.event_type === EVENT_TYPES.FREE) {
            setFaqData(eventFaqConstants.filter((eventFaq) => eventFaq.id > 2 && eventFaq.id < 7));
        } else {
            setFaqData(eventFaqConstants.filter((eventFaq) => eventFaq.id < 7));
        }
    }, []);

    const [openIdAdmission, setOpenIdAdmission] = useState(1);
    const recordAccordionIdAdmission = (id) => {
        if (id === openIdAdmission) setOpenIdAdmission(-1);
        else setOpenIdAdmission(id);
    };

    return (
        <div className="event-faq-wrapper">
            <h3>Frequently Asked Questions</h3>

            {faqData &&
                faqData.map((accordionData, index) => (
                    <CustomMadeAccordion
                        identifier={accordionData.id}
                        key={index}
                        accordionData={accordionData}
                        recordAccordionId={recordAccordionIdAdmission}
                        isOpen={accordionData.id === openIdAdmission}
                    />
                ))}
        </div>
    );
};

export default EventFaq;
