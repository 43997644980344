import React from "react";
import "./styles/LearningOutcomes.css";
import whiteBgTicks from "../../Resources/Images/learningOutcomesTick.svg";
const LearningOutcomes = (props) => {
    const learningOutcomes = props.event_details.learning_outcomes;
    return (
        <div className="learning-outcomes-wrapper">
            <h3 className="learning-outcomes-heading">Learning Outcomes</h3>
            <div className="outcome-card-wrapper">
                {learningOutcomes &&
                    learningOutcomes.map((learning_outcome) => (
                        <div className="outcome-card">
                            <div className="outcome-card-tick">
                                <img src={whiteBgTicks} alt="" />
                            </div>
                            <p>{learning_outcome}</p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default LearningOutcomes;
