import React, { useState } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import ActionButton from "../Actionbutton/ActionButton";
import { style } from "../Common/Theme";
import "./styles/StartTrial.css";

function StartTrial() {
    const [isEmailValid, setIsEmailValid] = useState(true);
    const { loggedIn } = useGlobalContext();

    const getFreeTrail = () => {
        return (
            <div className="footer-make-your-own-title-free-trial-wrapper">
                <div className="footer-make-your-own-title-text">
                    <h3>Make Your Own Success Story!</h3>
                </div>
                <div className="footer-start-free-trial-text">
                    Start Your 7 Days Free Trial Now!
                </div>
                <div
                    onClick={() =>
                        window.open("https://renaissance.programmingpathshala.com/signup", "_blank")
                    }
                >
                    <ActionButton name="Star Free Trial" style={style} />
                </div>
            </div>
        );
    };

    return <>{!loggedIn ? getFreeTrail() : <></>}</>;
}

export default StartTrial;
