import React from "react";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material";
import { theme } from "../Common/Theme";

const LoginButton = () => {
    const style = {
        border: "1px solid #444BAB",
        color: "#444bab",
        fontFamily: "Gilroy-Bold",
        textTransform: "capitalize",
        padding: "0.29vw 1.8vw",

        "&:hover": {
            backgroundColor: "#fff",
            border: "1px solid #444BAB",
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <div>
                <Button sx={style} variant="outlined">
                    Log In
                </Button>
            </div>
        </ThemeProvider>
    );
};

export default LoginButton;
