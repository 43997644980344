import React from "react";
import "./styles/AboutEvent.css";
import CallIcon from "@mui/icons-material/Call";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import useWindowDimensions from "../../../../Hooks/UseWindowDimensions";
import { EVENT_TYPES } from "../../../../Constants/EventsConstants/EventsConstants";
import { isNULLEMPTYORUNDEFINED } from "../../../../Utilities/CommonUtility/CommonUtility";

function AboutEvent(props) {
    const { setConsultationLeadsModalOpen } = useGlobalContext();
    const { windowWidth } = useWindowDimensions();
    const { setRegisterFormModalOpen } = useGlobalContext();

    const event_abouts = props.event_details.event_about;

    const scrollToRegForm = () => {
        if (windowWidth > 600) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 450, left: 0, behavior: "smooth" });
        }
        setRegisterFormModalOpen(true);
    };

    const about_text_paragraph =
        event_abouts &&
        event_abouts.map((event_about, index) => {
            return <p key={index}>{event_about}</p>;
        });

    return (
        <div className="about-event-wrapper">
            <h1>About The Event</h1>
            <div className="about-event-points-wrapper">{about_text_paragraph}</div>

            <button className="about-event-time-wrapper">
                {props.event_details.event_type === EVENT_TYPES.FREE ? (
                    <div>Free Live Class on {props.event_details.date_string}</div>
                ) : (
                    <div>Weekend LIVE Classes Starting on {props.event_details.date_string}</div>
                )}
            </button>

            {/* {props.event_details.event_type === EVENT_TYPES.PAID && windowWidth < 600 && (
                <button
                    className="book-consultation-wrapper"
                    onClick={() => setConsultationLeadsModalOpen(true)}
                >
                    <span> Book free consultation </span>
                    <CallIcon sx={{ color: "#231f20" }} />
                </button>
            )} */}
            <div className="about-event-register-btn">
                <button onClick={scrollToRegForm}>Register Now</button>
            </div>
        </div>
    );
}

export default AboutEvent;
